<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="70vw"
    class="pdf-modal"
  >
    <div class="pdf-modal-container">
      <embed :src="url" type="application/pdf" class="pdf-viewer" />
    </div>
  </a-modal>
</template>

<script>

export default {
  props: {
    visible: Boolean,
    url: String,
    pdfFileName: String,
  },
  emits: ["close-modal"],
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    downloadPdf() {
      const link = document.createElement("a");
      link.href = this.pdfUrl;
      link.target = "_blank";
      link.download = this.pdfFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss">
.pdf-modal {
  top: 5rem;
  margin: 0 auto;
  @include respond(tab-port) {
    width: 90vw !important;
  }
  .ant-modal-content {
    background-color: #f6f8ff;
    height: 100%;
    .ant-modal-close {
      display: none !important;
    }
    .ant-modal-body {
      padding: 0;
      .pdf-modal-container {
        padding: 2.2rem 2.5rem;
        overflow-x: auto;
        .pdf-viewer {
          width: 100% !important;
          height: calc(100vh - 15rem) !important;
        }
      }
    }
  }
}
</style>
